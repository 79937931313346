.WarningBannerContainer {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;

}
.WarningIcon {
    font-size: 32px;
    color: rgb(195, 91, 91);
    line-height: 1px;
}