.favorite-entry-container {
    background: #0e1c2b;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    line-height: 5px;
    height: 120px;
    min-width: 800px;
    color: #f4f4f4;
}

.favorite-entry-buttons-container {
    width: 200px;
    float: right;
}