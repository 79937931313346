/* Override some CSS properties from other modules */
.react-datepicker-popper {
/*  z-index: 9999!important;*/
/*  position: fixed!important;
  top: 200px!important;*/
}

input:focus {outline:none;}
button:focus {outline:none;}

/* Main CSS */
html {
  font-family: 'Oswald', Tahoma, sans-serif!important;
  height: 100%;
}
body {
  min-height: 100%;
  height: 100%;
}

.load-overlay{
  opacity:0.4;
/*  filter: alpha(opacity=20);*/
  background-color:#000; 
  width:100%; 
  height:100%; 
  z-index:9999;
  top:0; 
  left:0; 
  position:fixed; 
}

.centered-div {
  opacity: 0.9;
  z-index:9999!important;
  position: absolute;
  width: 260px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 0px;
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 4px 8px 15px #00000066;
}

.centered-div p {
  margin: 17px;
  color: #0d1b2a;
  font-size: 16px;
  font-weight: bold;
}

.load-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0D1B2A;
  -webkit-animation: fa-spin 1.5s infinite linear;
  animation: fa-spin 1.5s infinite linear;
}

#root{
  height: 100%;
}

#outer-container{
  height: 100%;
}

#page-wrap{
  height: 100vh;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background:  #0D1B2A;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fafafa;
}

/* General sidebar styles */
.bm-menu {
  background: #0D1B2A;
/*  padding: 2.5em 1.5em 0;*/
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
/*  padding: 0.8em;*/
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
/*  background: none!important;*/
}

.menu-login-div {
/*    background: #384a5d;*/
    color: #ffffff;
    font-size: 18px;
    padding: 10px;
    margin-top: 10px;
/*    border: 2px solid #162c53;
    border-radius: 5px;*/
  }
.menu-login-div p{
    margin: 0px;
  }
.menu-login-div button{
  padding: 5px;
}


.menu-header{
  color: #fafafa;
  height: 50px;
  background: linear-gradient(#5a6f86, #0e1c2b);
  height: 200px;
}
.menu-header h1{
  margin: 0px;
  padding-left: 10px;
  padding-top: 20px;
}
.menu-header-logo{
  max-width: 120px; 
  display: block; 
  margin-left: auto; 
  margin-right: auto;
  padding-top: 10px;
}
.menu-header-text{
  text-align: center; 
  color: #ffffff; 
  font-size: 18pt; 
  margin: 0px;
}
.menu-options{
/*  padding: 30px 10px 10px 10px;*/
  font-size: 14px;
  height: 100%;
}
.menu-options-scroll{
  height: -webkit-calc(100% - 200px);
  height: expression(100% - 200px);
  height: -moz-calc(1100% - 200px);
  height: -o-calc(100% - 200px);
  height: calc(100% - 200px);
  height: calc(100% - 200px);
}
.menu-options-inner{
  padding-left: 10px;
  padding-right: 10px;
}

.menu-options-label{
  margin: 12px 0px 2px 0px;
  color: #fafafa;
  display: inline-block;
/*  font-size: 16px;*/
}

.menu-options-info{
  font-size: 12px;
  margin-bottom:0px;
  margin-top:5px;
}

.sdate-div{
  width: 35%;
  float: left;
  display: inline-block;
}

.edate-div{
  width: 35%;
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.date-switch-div{
  display: inline-block;
  width: 25%;
  /* float: left; */
}

.match_button{
  font-size: 16px;
  color:#00BD70;
  cursor:pointer;
  position: relative;
  margin-left: 42%;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}

.match_button:hover{
  color:#019257;
}

.start_to_end_button{
  top: 20px;
}

.end_to_start_button{
  top: 6px;
}

.date-input{
  text-align: center;
  height: 25px;
  border-radius: 5px;
  border: none;
  padding: 5px;
  width: 100%;
}
.date-input:disabled {
  color: #8c8c8c!important;
  background: #40474d!important;
}

.qc-div{
  margin: 4px 0px 0px 0px;
  display: inline-block;
  float: right;
}

.plot-menu-button-div{
  padding-top: 20px;
  padding-bottom: 20px;
}
.plot-menu-button {
  color: #fafafa;
  background: #00BD70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
  font-size: 14px;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.plot-menu-button:hover {
  background: #019257;
}
.plot-menu-button:disabled {
  color: #8c8c8c;
  background: #40474d;
}


.clear-button {
  background: #c35b5b;
}

.clear-button:hover {
  background: #cc4444;
}

.react-responsive-modal-root {
  z-index: 9999!important;
}

.share-overlay{
  background: rgba(0,0,0,0.8);
  z-index: 9999!important;
}

.share-modal{
  border-radius: 5px;
  padding: 40px;
  z-index: 99999!important;
}

.share-close-button{
/*  top: -30px;
  right: -5px;*/
}
.share-close-button:hover{
  cursor: pointer;
}
.share-close-icon{
  fill: #0D1B2A!important;
  width: 30px;
  stroke: white;
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}
.share{
   color: #fafafa;
   cursor: pointer;
}

.share:hover{
  color: #bababa;
}

.share-link-div{
  box-sizing: border-box;
  display: table;
}

.buttonInside{
  width: 400px;
  position:relative;
  margin-bottom:10px;
}

.share-link-button{
  color: #4e4e4e;
  position: absolute;
  right: 0px;
  /* top: 4px; */
  border: none;
  height: 100%;
  width: 30px;
  /* border-radius: 100%; */
  outline: none;
  text-align: center;
  font-weight: bold;
  padding: 2px;
  background: blue;
  background-color: #eee;
  background-image: linear-gradient(#fcfcfc,#eee);
  border: 1px solid #d5d5d5;
}

.share-link-button:hover{
  cursor:pointer;
}

.share-link{
  height:25px;
  width:calc(100% - 35px);
  padding-left:10px;
/*  border-radius: 4px;*/
/*  border:none;outline:none;*/
  border: 1px solid #ccc;
    border-radius: 3px;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
}

.custom-toggle.react-toggle--checked .react-toggle-track {
  background-color: #00BD70;
}
.custom-toggle.react-toggle--checked:hover .react-toggle-track{
  background-color: #019257!important;
}

.custom-toggle .react-toggle-track {
  background-color: #585858;
}
.custom-toggle.custom-toggle.react-toggle:hover .react-toggle-track{
  background-color: #474747;
}

.react-toggle-thumb{
  box-shadow: none!important;
  -webkit-box-shadow: none!important;
}

.sidebar{
  position: fixed;
  left: 0px;
  right: 0px;
  top:0px;
  bottom: 0px;
  background: #0D1B2A;
  width: 75px;
}

.plot-area{
  height: 100%;
  padding: 0px 0px 0px 110px;
    white-space: nowrap;
  display: inline-block;
  float: right;
  width: 100%;
}

.plot-area p{
  margin: 0px 0px 2px 0px;
  font-weight: bold;
  font-size: 14px;

}

.plot-area-scroll{
  overflow: auto;
}

.custom-z-range-container {
  width: calc(100% - 30px);
  position: absolute;
}

.file-header-button{
  color: #fafafa;
  background: #0D1B2A;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
  font-size: 14px;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.file-header-button:hover {
  background: #060d15;
}
.file-header-button:disabled {
  color: #8c8c8c;
  background: #40474d
}


.stats-label{
  font-weight: bold;
  font-size: 16px;
}

.stats-value{
  float: right;
  font-size: 14px;
}

.help-modal-input{
  height: 25px;
  width: calc(100% - 15px);
  padding-left: 10px;
  /* border-radius: 4px; */
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
}

.multivar-help-container{
  position: absolute;
  left: 80px;
  /* padding: 10px; */
  top: 20px;
  background: #d0d0d0;
  z-index: 9999;

}

.multivar-help-container p {
    padding: 3px;
    color: #0d1b2a;
    margin: 0px;
    font-size: 12px;
}

.footer{
  background: #0d1b2a40;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: 10px;
  border-radius: 3px;
}
.footer p{
  font-size: 14px;
  color: #0D1B2A;
  margin: 3px;
}
.footer a{
  text-decoration: none!important;
}
.footer a:visited{
  color: #0D1B2A;
}
.footer a:active{
  color: #0D1B2A;
}